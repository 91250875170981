.warning{

    display: flex;
    /* text-align: justify; */
    background: var(--nextui-colors-errorLight);
    color: var(--nextui-colors-error);
    padding: 12px;
    border: 1px solid var(--nextui-colors-errorBorder);
    border-radius: 12px;
    
}



/* ----------------------------------------------
 * Generated by Animista on 2023-9-1 10:51:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */

.hidden{
    display: none !important;
}

.fade-in {
	-webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes scale-in-ver-top {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-in-ver-top {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  

.fade-out {
	-webkit-animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-1 10:53:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-ver-top
 * ----------------------------------------
 */
 @-webkit-keyframes scale-out-ver-top {
    0% {
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0);
                transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
        opacity: 1;
    }
  }
  @keyframes scale-out-ver-top {
    0% {
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(0);
                transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
                transform-origin: 100% 0%;
        opacity: 1;
    }
  }
  