
.sectionOne{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 45px;
  height: 45px;
  /* border-radius: 4px; */
  border: 4px solid #9800fe;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: transparent;
  transform-origin: center;
  transform: rotateX(600deg) rotate(45deg);
  position: relative;
  top: 25px;
}

.sectionTwo{
  height: 50px;
  width: 50px;
  /* border-radius: 5px; */
  border: 5px solid #9800fe;
  border-right: 0px;
  border-bottom: 0px;
  background: transparent;
  transform-origin: center;
  transform: rotateX(600deg) rotate(45deg);
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -8px auto;
  position: relative;
  top: -15px;
}

.sectionThree{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -12px auto;
  height: 50px;
  width: 50px;
  /* border-radius: 5px; */
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 5px solid #9800fe;
  border-right: 0px;
  border-bottom: 0px;
  background: transparent;
  transform-origin: center;
  transform: rotateX(600deg) rotate(45deg);
  position: relative;
  top: 0px;
}



  @keyframes appearFromBelowStatic1 {
      0% {
      opacity: 0;
      top:20px;
      }
      100% {
      opacity: 1;
      }
  }

  @keyframes appearFromBelowStatic2 {
      0% {
      opacity: 0;
      top:10px;
      }
      100% {
      opacity: 1;
      }
  }

  @keyframes appearFromBelowStatic3 {
      0% {
      opacity: 0;
      top: 20px;
      }
      100% {
      opacity: 1;
      }
  }

  @keyframes appearFromBelowDynamic1 {
      0% {
      opacity: 1;
      top: 35px;
      }

      50%{
      opacity: 0;
      top: 20px;
      }
      100% {
      opacity: 1;
      top: 35px;
      }
  }

  @keyframes appearFromBelowDynamic2 {
      0% {
      opacity: 1;
      top: 0px;
      }

      50%{
      opacity: 0;
      top: 5px;
      }
      100% {
      opacity: 1;
      top: 0px;
      }
  }

  @keyframes appearFromBelowDynamic3 {
      0% {
          opacity: 1;
          top: -32px;
          }

          50%{
          opacity: 0;
          top: -15px;
          }
          100% {
          opacity: 1;
          top: -32px;
          }
  }

  @keyframes appearFromBelowLogo {
      0% {
          opacity: 0;

          }
          100% {
          opacity: 1;
          }
  }

  /* Agrega la clase de animación a los elementos que deseas animar */
  .animationStaticOne{
      animation: appearFromBelowStatic1 1s ease-in-out forwards;
  }

  .animationStaticTwo {
      animation: appearFromBelowStatic2 1s ease-in-out forwards;
  }

  .animationStaticThree {
      animation: appearFromBelowStatic3 1s ease-in-out forwards;
  }

  .animationDynamicOne{
      animation: appearFromBelowDynamic1 1.5s ease-in-out forwards infinite;
  }

  .animationDynamicTwo {
      animation: appearFromBelowDynamic2 1.5s ease-in-out forwards infinite;
  }

  .animationDynamicThree {
      animation: appearFromBelowDynamic3 1.5s ease-in-out forwards infinite;
  }

  .logo{
      animation: appearFromBelowLogo 2s ease forwards;
  }
