
.sectionOne{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    border: 5px solid #9800fe;
    background: transparent;
    transform-origin: center;
    transform: rotateX(600deg) rotate(45deg);
    position: relative;
    top: 35px;
}

.sectionTwo{
    width: 45px;
    height: 45px;
    border-radius: 5px;
    border: 5px solid #9800fe;
    border-right: 0px;
    border-bottom: 0px;
    background: transparent;
    transform-origin: center;
    transform: rotateX(600deg) rotate(45deg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    top: 0px;
}

.sectionThree{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    border: 5px solid #9800fe;
    border-right: 0px;
    border-bottom: 0px;
    background: transparent;
    transform-origin: center;
    transform: rotateX(600deg) rotate(45deg);
    position: relative;
    top: -35px;
}



    @keyframes appearFromBelowStatic1 {
        0% {
        opacity: 0;
        top:20px;
        }
        100% {
        opacity: 1;
        }
    }

    @keyframes appearFromBelowStatic2 {
        0% {
        opacity: 0;
        top:10px;
        }
        100% {
        opacity: 1;
        }
    }

    @keyframes appearFromBelowStatic3 {
        0% {
        opacity: 0;
        top: 20px;
        }
        100% {
        opacity: 1;
        }
    }

    @keyframes appearFromBelowDynamic1 {
        0% {
        opacity: 1;
        top: 35px;
        }

        50%{
        opacity: 0;
        top: 20px;
        }
        100% {
        opacity: 1;
        top: 35px;
        }
    }

    @keyframes appearFromBelowDynamic2 {
        0% {
        opacity: 1;
        top: 0px;
        }

        50%{
        opacity: 0;
        top: 5px;
        }
        100% {
        opacity: 1;
        top: 0px;
        }
    }

    @keyframes appearFromBelowDynamic3 {
        0% {
            opacity: 1;
            top: -32px;
            }

            50%{
            opacity: 0;
            top: -15px;
            }
            100% {
            opacity: 1;
            top: -32px;
            }
    }

    @keyframes appearFromBelowLogo {
        0% {
            opacity: 0;

            }
            100% {
            opacity: 1;
            }
    }

    /* Agrega la clase de animación a los elementos que deseas animar */
    .animationStaticOne{
        animation: appearFromBelowStatic1 1s ease-in-out forwards;
    }

    .animationStaticTwo {
        animation: appearFromBelowStatic2 1s ease-in-out forwards;
    }

    .animationStaticThree {
        animation: appearFromBelowStatic3 1s ease-in-out forwards;
    }

    .animationDynamicOne{
        animation: appearFromBelowDynamic1 1.5s ease-in-out forwards infinite;
    }

    .animationDynamicTwo {
        animation: appearFromBelowDynamic2 1.5s ease-in-out forwards infinite;
    }

    .animationDynamicThree {
        animation: appearFromBelowDynamic3 1.5s ease-in-out forwards infinite;
    }

    .logo{
        animation: appearFromBelowLogo 2s ease forwards;
    }
