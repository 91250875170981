
.asset{
    transition: 0.4s ease-in-out;
}

.asset:hover{
    outline: 1px solid var(--nextui-colors-primary);

}

.chip:hover{
    cursor: pointer;    
}


.selected {
    -webkit-box-shadow: 0px 0px 22px 0px var(--nextui-colors-primary);
    -moz-box-shadow: 0px 0px 22px 0px var(--nextui-colors-primary);
    box-shadow: 0px 0px 22px 0px var(--nextui-colors-primary);
    outline: 1px solid var(--nextui-colors-primary);
}


.button {
    height: 30px;
    min-width:75px;
    width: "";
    background-color: var(--nextui-colors-primary);
    border: none;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.button:hover {
    background-color: var(--nextui-colors-primary);
}
.button:focus {
    outline: none;
}

.button:active {
    background-color: #d2d2d2;
}


.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-30 14:20:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
    0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
    }
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
    }
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
}

.scale-out-center {
	-webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    
    
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-30 14:20:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
        0% {
            -webkit-transform: scale(1);
                    transform: scale(1);
            opacity: 1;
        }
        100% {
            width: 0px;
            -webkit-transform: scale(0);
                    transform: scale(0);
            opacity: 1;
            width: 0;
        }
    }
@keyframes scale-out-center {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 1;
        width: 0;
    }
}
  