.filters {
    background: var(--nextui-colors-backgroundContrast);
    padding: 5px;
    border-radius: 14px;
}



.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2023-8-30 14:20:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
    0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
    }
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
    }
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
}

.scale-out-center {
	-webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    
    
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-30 14:20:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
        0% {
            -webkit-transform: scale(1);
                    transform: scale(1);
            opacity: 1;
        }
        100% {
            width: 0px;
            -webkit-transform: scale(0);
                    transform: scale(0);
            opacity: 1;
            width: 0;
        }
    }
@keyframes scale-out-center {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 1;
        width: 0;
    }
}

