

.react-tabs {

}

.react-tabs__tab-list{
  /* styles for the list of tabs */
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}


.react-tabs__tab{
  /* styles fot the tab */
  /* background: var(--nextui-colors-backgroundContrast); */
  background:  #27272a;
  padding: 10px;
  cursor: pointer;
  padding: 10px;
}

.react-tabs__tab:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.react-tabs__tab:last-child{
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.react-tabs__tab--selected{
  /* styles applyed to selected tab */
  background: #36363b;

}:focus-visible{
  outline: unset !important;
}
