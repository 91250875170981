.sidenav {
  position: fixed;
  top: 75px;
  left: 0;
  height: calc(100% - 70px);
  width: 100px;
  z-index: 10;
  /* background-color: #09090c; */
  background-color: var(--nextui-colors-backgroundContrast);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 30px;

  /* background-color: var(--nextui--navbarBlurBackgroundColor); */
  padding: 10px;

}

.disbled{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding: 18px;
  border-radius: 20px;
  margin: 0px 0px;
  color: gray;
}

.sideNavElement {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding: 18px;
  border-radius: 20px;
  margin: 0px 0px;
}

.sideNavElement:hover {
  cursor: pointer;
  background-color: var(--nextui-colors-background);
  background-color: var(--nextui-colors-sideNavHover);

}

.sideNavIcon {
  width: 50px;
}
