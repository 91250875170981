body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Next UI table header override */
.nextui-table-column-header {
  background: #7828c866 !important;
  color: white !important
}

.nextui-table-select-all-checkbox {
  background: #7828c866 !important;
  color: white !important
}

/* The css below is for amplify authenticator */

.amplify-field__show-password{
  background: #363156 !important;
  /* #363156 */
}

input.amplify-input{
  background: #363156 !important;
  color: white !important;
}

/* Below code is for the tag that matches <div data-amplify-router> -> the container of the login form */
div[data-amplify-router]{
  border-radius: 12px !important;
}

#root{
  --amplify-background-color: #363156 !important;
  width: 100%;
}

div[data-amplify-authenticator] {
  display: grid;
  align-items: center;
  justify-items: center;
  height: 100vh;
}

div.amplify-alert--error{
  border-radius: 12px;
}

button.amplify-button--link:hover{
  background-color: transparent !important; /* this is to remove the background color when hovering over the link (forgot pasword, go back, etc) */
}