



/* Next rule applies to all elements that contain nextui-button--ready class ->  https://stackoverflow.com/questions/13352080/match-all-elements-having-class-name-starting-with-a-specific-string*/ 
.all button[class*='nextui-button--ready'] { 
    background: #0a2440  !important;
    color: #06b7db !important;
}
.all button:hover{
    background: #66daf1af  !important;
    color: #0a2440 !important;
}

.all [class*='nextui-badge'] {
    --nextui--badgeBackgroundColor: #2788f0 !important; 
    --nextui--badgeTextColor: #ffffff !important; 
}

.allLigth button[class*='nextui-button--ready'] { 
    background:  #66daf1af  !important;
    color: #0a2440 !important;
}
.allLigth button:hover{
    background: #b8edf8bd  !important;
    color: #06b7db !important;
}

.allLigth [class*='nextui-badge'] {
    --nextui--badgeBackgroundColor: #2788f0 !important; 
    --nextui--badgeTextColor: #ffffff !important; 
}