.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: var(--nextui-colors-primary) !important;
  --rdp-background-color: var(--nextui-colors-primary) !important;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: var(--nextui-colors-primary) !important;
  --rdp-background-color-dark: var(--nextui-colors-background) !important;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color) !important;
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid var(--rdp-accent-color) !important;
}
