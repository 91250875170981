.warning{

    display: flex;
    /* text-align: justify; */
    background: var(--nextui-colors-errorLight);
    color: var(--nextui-colors-error);
    padding: 12px;
    border: 1px solid var(--nextui-colors-errorBorder);
    border-radius: 12px;
    
}