.expandable{
    transition: max-height 1s ease-in-out;
    overflow: hidden;
    max-height: 250px;
    
}

.expanded{
    max-height: 100vh;
    overflow: auto;
}