/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */

/* Define the scrollbar style */
::-webkit-scrollbar {
  width: 5px;

}

::-webkit-scrollbar-track {
  background-color: var(--nextui-colors-background);
}

::-webkit-scrollbar-thumb {
  background-color: var(--nextui-colors-primaryLight);
}

::-moz-selection {
  background: #f1f2f32a;
}
::-webkit-selection {
  background: #f1f2f32a;
}
::selection {
  background: #f1f2f32a;
}

.discount {
  background: var(--nextui-colors-primaryLight);
  color: var(--nextui-colors-purple800);
  border-radius: 6px !important;
  padding: 0 3px 0px 3px;
}

